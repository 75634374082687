<template>
    <section class="my-reward d-flex justify-content-center">
        <div class="w-50">
            <div class="card">
                <div class="card-body">
                    <div class="container-fluid text-left">
                        <h4 class="font-weight-bold mb-2">My Rewards</h4>
                        <img src="@/assets/images/banner_nataru.svg" alt="Nataru" class="img-fluid w-100">
                        <div class="d-flex align-items-center mt-3">
                            <img src="@/assets/images/icons/ic_calendar_nataru.svg" alt="calendar" class="img-fluid">
                            <small class="ml-1 mt-1"><i style="color:#585859; font-size:12px !important">Valid until Jan 1, 2023</i></small>
                        </div>
                        <h5 class="font-weight-bold mb-1 mt-3">Christmas With Soca</h5>
                        <p class="font-weight-normal mb-1">{{ seeMore == false ? dummyText.slice(0,150) + '...' : dummyText }} <span v-if="!seeMore" @click="seeMore = !seeMore" class="text-muted cursor-pointer">lihat lebih banyak</span></p>
                        <template v-if="seeMore">
                            <h5 class="font-weight-bold mb-1 mt-3">Syarat dan ketentuan</h5>
                            <ul class="mb-2 ml-4">
                                <li>
                                    <p class="font-weight-normal mb-0">Quiz yang dimainkan hanya berlaku untuk quiz yang ada di kategori Christmas Collections</p>
                                </li>
                                <li>
                                    <p class="font-weight-normal mb-0">Follow akun @soca_ai</p>
                                </li>
                                <li>
                                    <p class="font-weight-normal mb-0">Subscribe channel Youtube Soca AI</p>
                                </li>
                                <li>
                                    <p class="font-weight-normal mb-0">Screenshot halaman profile kamu di aplikasi Soca AI dan share point di story IG kamu, dengan hastag <b><i>#ChristmasWithSoca #AvatarGifts #PlayToLearn</i></b><span v-if="seeMore" @click="seeMore = !seeMore" class="text-muted cursor-pointer ml-2">lihat lebih sedikit</span></p>
                                </li>
                            </ul>
                        </template>
                        <!-- YOUR GIFTS -->
                        <div class="your-gifts mt-3 mb-4">
                            <h4 class="mb-2">Your Gifts</h4>

                            <!-- 500 POINTS -->
                            <div class="card" :class="myRewards.prize1 == true ? 'bg-disabled' : ''">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-1 text-center">
                                                <img :src="myRewards.prize1 == true ? require(`@/assets/images/icons/coins_disabled.svg`) : require(`@/assets/images/icons/coins_500.svg`)" alt="500 coins" class="img-fluid">
                                                <p class="text-danger font-weight-bold mb-0 point-p" :class="myRewards.prize1 == true ? 'fc-disabled' : ''">500pt</p>
                                            </div>
                                            <div class="col-md-11">
                                                <div class="ml-3">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <p class="mb-0 font-weight-bold" :class="myRewards.prize1 == true ? 'fc-disabled' : ''">Special Avatars</p>
                                                        <p v-if="myRewards.prize1 == true" class="mb-0 fc-disabled"><i>Claimed</i></p>
                                                    </div>
                                                    <small :class="myRewards.prize1 == true ? 'fc-disabled' : ''">Kumpulkan 500point untuk mendapatkan reward ini.</small>
                                                    <b-progress height="5px" class="mt-1" :value="myRewards.scorePrize1" :max="500" :variant="myRewards.prize1 == true ? 'secondary' : 'warning'"></b-progress>
                                                    <small class="float-right text-muted mt-1" :class="myRewards.prize1 == true ? 'fc-disabled' : ''"><span class="text-warning" :class="myRewards.prize1 == true ? 'fc-disabled' : ''">{{ myRewards.scorePrize1 >= 500 ? '500' : formatPoint(myRewards.scorePrize1) }}</span> / 500</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 2.500 POINTS -->
                            <div class="card mt-3" :class="myRewards.prize2 == true ? 'bg-disabled' : ''">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-1 text-center">
                                                <img :src="myRewards.prize2 == true ? require(`@/assets/images/icons/coins_disabled.svg`) : require(`@/assets/images/icons/coins_2500.svg`)" alt="22500 coins" class="img-fluid">
                                                <p class="font-weight-bold mb-0 point-p" style="color:#A532FF;" :class="myRewards.prize2 == true ? 'fc-disabled' : ''">2.500pt</p>
                                            </div>
                                            <div class="col-md-11">
                                                <div class="ml-3">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <p class="mb-0 font-weight-bold" :class="myRewards.prize2 == true ? 'fc-disabled' : ''">Learning Kits</p>
                                                        <p v-if="myRewards.prize2 == true" class="mb-0 fc-disabled"><i>Claimed</i></p>
                                                    </div>
                                                    <small :class="myRewards.prize2 == true ? 'fc-disabled' : ''">Kumpulkan 2500point untuk mendapatkan reward ini.</small>
                                                    <b-progress height="5px" class="mt-1" :value="myRewards.scorePrize2" :max="2500" :variant="myRewards.prize2 == true ? 'secondary' : 'warning'"></b-progress>
                                                    <small class="float-right text-muted mt-1" :class="myRewards.prize2 == true ? 'fc-disabled' : ''"><span class="text-warning" :class="myRewards.prize2 == true ? 'fc-disabled' : ''">{{ myRewards.scorePrize2 >= 2500 ? '2.500' : formatPoint(myRewards.scorePrize2) }}</span> / 2.500</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- 5.000 POINTS -->
                            <div class="card mt-3" :class="myRewards.prize3 == true ? 'bg-disabled' : ''">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-1 text-center">
                                                <img :src="myRewards.prize3 == true ? require(`@/assets/images/icons/coins_disabled.svg`) : require(`@/assets/images/icons/coins_5000.svg`)" alt="5000 coins" class="img-fluid">
                                                <p class="font-weight-bold mb-0 point-p" style="color:#2B87E3;" :class="myRewards.prize3 == true ? 'fc-disabled' : ''">5.000pt</p>
                                            </div>
                                            <div class="col-md-11">
                                                <div class="ml-3">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <p class="mb-0 font-weight-bold" :class="myRewards.prize3 == true ? 'fc-disabled' : ''">Soca Merchandise</p>
                                                        <p v-if="myRewards.prize3 == true" class="mb-0 fc-disabled"><i>Claimed</i></p>
                                                    </div>
                                                    <small :class="myRewards.prize3 == true ? 'fc-disabled' : ''">Kumpulkan 5.000point & 20plays untuk mendapatkan reward ini.</small>
                                                    <b-progress height="5px" class="mt-1" :value="myRewards.scorePrize3" :max="5000" :variant="myRewards.prize3 == true ? 'secondary' : 'warning'"></b-progress>
                                                    <small class="float-right text-muted mt-1" :class="myRewards.prize3 == true ? 'fc-disabled' : ''"><span class="text-warning" :class="myRewards.prize3 == true ? 'fc-disabled' : ''">{{ myRewards.scorePrize3 >= 5000 ? '5.000' : formatPoint(myRewards.scorePrize3) }}</span> / 5.000</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 10.000 POINTS -->
                            <div class="card mt-3" :class="myRewards.prize4 == true ? 'bg-disabled' : ''">
                                <div class="card-body">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-1 text-center">
                                                <img :src="myRewards.prize4 == true ? require(`@/assets/images/icons/coins_disabled.svg`) : require(`@/assets/images/icons/coins_10000.svg`)" alt="10000 coins" class="img-fluid">
                                                <p class="font-weight-bold mb-0 point-p" style="color:#40BE45;" :class="myRewards.prize4 == true ? 'fc-disabled' : ''">10.000pt</p>
                                            </div>
                                            <div class="col-md-11">
                                                <div class="ml-3">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <p class="mb-0 font-weight-bold" :class="myRewards.prize4 == true ? 'fc-disabled' : ''">Amazon Kindle</p>
                                                        <p v-if="myRewards.prize4 == true" class="mb-0 fc-disabled"><i>Claimed</i></p>
                                                    </div>
                                                    <small :class="myRewards.prize4 == true ? 'fc-disabled' : ''">Kumpulkan 10.000point & 50plays untuk mendapatkan reward ini.</small>
                                                    <b-progress height="5px" class="mt-1" :value="myRewards.scorePrize4" :max="10000" :variant="myRewards.prize4 == true ? 'secondary' : 'warning'"></b-progress>
                                                    <small class="float-right text-muted mt-1" :class="myRewards.prize4 == true ? 'fc-disabled' : ''"><span class="text-warning" :class="myRewards.prize4 == true ? 'fc-disabled' : ''">{{ myRewards.scorePrize4 >= 10000 ? '10.000' : formatPoint(myRewards.scorePrize4) }}</span> / 10.000</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getAccountInfo } from '@/services/user/user.service'
import { getMyRewards } from '@/services/my-rewards/my_rewards.service'

export default {
    data(){
        return {
            valueDummy: 75,
            accountInfo: [],
            myRewards: [],
            seeMore : false,
            dummyText: "Natal akan datang, bersiaplah untuk pesta. Merayakan Natal bareng Soca akan lebih seru dan asyik dengan bermain quiz berhadiah dari soca, tunggu apalagi yuk mainkan quiz Christmas Collections di Soca."
        }
    },

    mounted(){
        this.getAccount()
        this.getRewards()
    },

    methods:{
        getAccount(){
           getAccountInfo()
           .then((response) => {
                if(response.status && response.status == true){
                    this.accountInfo = response.data
                }
           })
        },

        getRewards(){
            getMyRewards()
            .then((response) => {
                if(response.status && response.status == true){
                    this.myRewards = response.data
                }
            })
        },

        formatPoint(val){
            if(val){
                const formatter = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                })
                return formatter.format(val)
            } else {
                return val
            }
        },

        seeMoreText(){
            this.seeMore = true
        }
    }
}
</script>

<style scoped>
.my-reward .card{
    border-radius: 16px;
}

.my-reward .point-p{
    font-size: 12px !important;
}

.bg-disabled {
    background-color:#E4E4E4 !important;
}

.fc-disabled{
    color: #AAAAAA !important;
}
</style>